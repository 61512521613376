<template>
<agregarRolComponente></agregarRolComponente>
</template>

<script>
import agregarRolComponente from '@/components/usuarios/agregarRolComponente.vue';

    export default {

        name : 'NuevoRol',

        components :{ agregarRolComponente },

        data() {
            return {

            }
        }
        
    }
</script>

<style scoped>

</style>